module.exports = [{
      plugin: require('/Users/xynxu/Documents/work/maison-hanko/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"maison-hanko","short_name":"Maison Hanko","start_url":"/","background_color":"#0E0E0E","theme_color":"#0E0E0E","display":"minimal-ui","icon":"src/images/site-icon.png"},
    },{
      plugin: require('/Users/xynxu/Documents/work/maison-hanko/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"109662578-3","head":true},
    },{
      plugin: require('/Users/xynxu/Documents/work/maison-hanko/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
