// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-artist-js": () => import("/Users/xynxu/Documents/work/maison-hanko/src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-pages-404-js": () => import("/Users/xynxu/Documents/work/maison-hanko/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/xynxu/Documents/work/maison-hanko/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/Users/xynxu/Documents/work/maison-hanko/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/xynxu/Documents/work/maison-hanko/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-talent-js": () => import("/Users/xynxu/Documents/work/maison-hanko/src/pages/talent.js" /* webpackChunkName: "component---src-pages-talent-js" */)
}

